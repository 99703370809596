<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./EmailBlock.svelte";
  import { getNewsletterStores } from "../../newsletterStores";
  import { LinkContent } from "../utils";
  import IfOutlook from "./helpers/IfOutlook.svelte";
  import EndIfOutlook from "./helpers/EndIfOutlook.svelte";
  import IfNotOutlook from "./helpers/IfNotOutlook.svelte";
  import EndIfNotOutlook from "./helpers/EndIfNotOutlook.svelte";

  export let block: schema.ButtonBlock;
  const { design, ctx } = getNewsletterStores();
  const useOutlookComments = ctx.dialect !== "non_standard_mns";
  const forceOutlookVersion = ctx.dialect === "constant_contact";

  $: textColorFromStyle = (style: schema.BlockStyle) => {
    if (!style) {
      style = "solid";
    }

    return style == "transparent" ? "#505060" : $design!.background.dark ? "#fff" : $design!.color.button.text;
  };

  $: backgroundColorFromStyle = (style: schema.BlockStyle, options: { isOutlook: boolean } = { isOutlook: false }) => {
    if (!style) {
      style = "solid";
    }

    if (style === "transparent") {
      return options.isOutlook ? "white" : "transparent";
    }

    return $design.color.button.background;
  };

  $: borderFromStyle = (style: schema.BlockStyle) => {
    if (!style) {
      style = "solid";
    }

    if (style === "transparent") {
      return `2px solid ${$design.color.button.background}`;
    }

    return "unset";
  };

  $: borderRadius = (shape: schema.BlockShape) => {
    switch (shape) {
      case "rounded":
        return "71px";
      case "square":
      default:
        return "4px";
    }
  };

  $: width = (layout: schema.BlockLayout) => {
    switch (layout) {
      case "narrow":
        return "auto";
      case "wide":
      default:
        return "100%";
    }
  };

  $: display = (layout: schema.BlockLayout) => {
    if (layout === "narrow") {
      return "inline-block";
    }
    return "block";
  };
</script>

<Block {block} let:layout let:style let:shape>
  {#if useOutlookComments}
    {#if !forceOutlookVersion}
      <IfOutlook />
    {/if}
    <table
      bgcolor={backgroundColorFromStyle(style, { isOutlook: true })}
      width={width(layout)}
      cellpadding="0"
      cellspacing="0"
      align="center"
      style="border-collapse: collapse;border:{borderFromStyle(style)}">
      <tr>
        <td colspan="3" height="6"></td>
      </tr>
      <tr>
        <td width="24"></td>
        <td width={width(layout)} align="center">
          <LinkContent
            cta={block.cta}
            type="button"
            blockId={block._id}
            linkText={block.text}
            style="font-weight:700;font-size: 21px;color:{textColorFromStyle(style)};text-decoration:none;">
            {block.text}
          </LinkContent>
        </td>
        <td width="24"></td>
      </tr>
      <tr>
        <td colspan="3" height="6"></td>
      </tr>
    </table>
    {#if !forceOutlookVersion}
      <EndIfOutlook />
    {/if}
  {/if}

  {#if !forceOutlookVersion}
    <IfNotOutlook />
    <table width="100%" cellpadding="0" cellspacing="0" style="border-collapse: collapse;">
      <tr>
        <td width={width(layout)} valign="middle" align="center">
          <LinkContent
            cta={block.cta}
            type="button"
            blockId={block._id}
            linkText={block.text}
            style="display:{display(layout)};min-width:240px;">
            <div
              style="font-weight:700;font-size: 21px;color:{textColorFromStyle(
                style
              )};text-decoration:none;line-height:1.5!important;padding: 12px 24px;border-radius:{borderRadius(
                shape
              )}; border:{borderFromStyle(style)};background-color: {backgroundColorFromStyle(style)};">
              {block.text}
            </div>
          </LinkContent>
        </td>
      </tr>
    </table>
    <EndIfNotOutlook />
  {/if}

  {#if block.details}
    <p
      style="opacity:0.75;margin-top:8px;line-height: 1.375;font-size:14px;text-align:center;color:{$design.background.dark
        ? '#fff'
        : $design.color['global.text']}">
      {#each block.details.trim().split("\n") as l}{l}<br />{/each}
    </p>
  {/if}
</Block>

<script lang="ts">
  import FieldLabel from "./FieldLabel.svelte";

  export let id: string;
  export let value: any;
  export let label: string | undefined;
  export let error: string | undefined = undefined;
  export let required: boolean = false;
  export let icon: string | undefined = undefined;
  export let disabled: boolean = false;
  export let size: "sm" | "md" | "lg" = "md";
  export let isFocused: boolean = false;
  export let rtl: boolean = false;

  let scrollTop = 0;

  function scroll(this: HTMLDivElement) {
    scrollTop = this.scrollTop;
  }

  // If we click on a label and we're already focused, just do nothing
  function skipIfFocused(e: any) {
    if (isFocused && !(e.target.localName in { textarea: 1, input: 1, select: 1 })) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }
</script>

<FieldLabel {label} {id} {required} {error} on:mousedown={skipIfFocused}>
  <div
    class="field relative flex items-center rounded border bg-white px-1 {size} gap-px overflow-y-auto"
    class:border-teal-400={isFocused}
    class:border-gray-300={!isFocused}
    class:error={!!error}
    class:focus={isFocused}
    class:flex-row-reverse={rtl}
    on:scroll={scroll}>
    <!-- svelte-ignore a11y-autofocus -->
    {#if icon}
      <span class="material-icons pointer-events-none flex select-none items-center justify-center self-center text-15 text-gray-600"
        >{icon}</span>
    {/if}
    <slot name="left" {value} {size} {required} {disabled} {label} {isFocused} {scrollTop} {rtl} />

    <slot {value} {size} {required} {disabled} {label} {isFocused} {scrollTop} {rtl} />

    <slot name="right" {value} {size} {required} {disabled} {label} {isFocused} {scrollTop} {rtl} />
  </div>
</FieldLabel>

<style>
  .field {
    --border-focus: 0 0 0 4px #dcf4f1;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    max-height: var(--max-height, "auto");
  }

  .form-input.sm {
    padding-top: 0.15em;
    padding-bottom: 0.15em;
  }

  .field.focus {
    outline: none;
    box-shadow: var(--border-focus);
  }

  .field.error {
    border-color: #ad282585;
  }
  .field.error.focus {
    border-color: #ad2825;
    box-shadow: 0 0 0 4px #f9e7e7;
  }

  :global(.field *::selection) {
    background-color: var(--selection-color);
  }

  :global(.field *::-moz-selection) {
    background-color: var(--selection-color);
  }
</style>

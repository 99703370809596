<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./Block.svelte";
  import { LinkContent } from "./utils";

  export let block: schema.EmbedFileBlock;

  let fileSize = 0;
  let fileSizeUnit = "KB";

  $: {
    fileSize = (block.file_size_in_bytes || 0) / 1024;
    if (fileSize > 1024) {
      fileSize = fileSize / 1024;
      fileSizeUnit = "MB";
    }
  }
</script>

<Block {block} on:click on:delete on:duplicate let:isEmpty showEmpty layouts={["md"]}>
  <LinkContent
    type="file"
    linkText={block.file_name}
    cta={{
      id: `file-${block._id}`,
      url: block.access_url
    }}>
    <div
      class="ada-no-shadow ada-border-black flex w-full flex-col items-start justify-between gap-2 rounded-md border bg-white p-3 pr-4 shadow sm:flex-row md:items-center">
      <div class="flex items-center justify-start text-gray-700">
        <div class="relative -mr-2 hidden sm:block">
          <div class="absolute inset-0 flex items-center justify-center text-10 font-bold uppercase tracking-wider text-white">
            {block.file_type}
          </div>
          <div class="material-icons-round notranslate ada-text-black text-5xl text-nxgray-300">insert_drive_file</div>
        </div>
        <div class="sm:ml-4">
          <div class="break-all text-md font-bold leading-tight text-nxgray-700" title={block.file_name}>{block.file_name}</div>
          <div class="mt-1 text-sm leading-tight text-brand-gray-70">{block.file_description || ""}</div>
        </div>
      </div>
      <span class="ada-text-black flex-shrink-0 text-center font-medium text-teal-600 sm:ml-4">
        <div class="text-14">Download</div>
        <div class="ada-text-black text-11 font-normal text-brand-gray-70">{fileSize.toFixed(1)} {fileSizeUnit}</div>
      </span>
    </div>
  </LinkContent>
</Block>

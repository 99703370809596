import type { version_0 as ver_from, version_1 as ver_to } from "../defs";
import type { v0Date } from "@editor/schema/defs/version_0";
import { DateTimeTZUtils } from "@editor/app/blocks/event/DateTimeTZUtils";

let _currentUserId: string;
export function migrate(newsletter: ver_from.Newsletter, currentUserId: string): ver_to.Newsletter {
  _currentUserId = currentUserId;
  try {
    return {
      _v: 1,
      id: newsletter.id,
      short: newsletter.short,
      content: migrateContent(newsletter.content),
      design: migrateDesign(newsletter.design),
      editors: {}
    };
  } catch (err) {
    console.log(`There is an error migrating newsletter`, currentUserId, JSON.stringify(newsletter));
    throw err;
  }
}

function migrateContent(newsletterContent: ver_from.NewsletterContent): ver_to.NewsletterContent {
  if (!newsletterContent || !Array.isArray(newsletterContent)) {
    return newsletterContent;
  }

  const [header, ...content] = newsletterContent;

  return {
    logo: { _t: "logo", _l: "-", _id: id(), _s: "empty", _o: "", _style: "-" },
    header: migrateHeader(header as ver_from.HeaderWidget),
    blocks: content.map(migrateWidget),
    footer: { _t: "signature", _l: "-", _id: id(), _s: "empty", _o: "", _style: "-" }
  };
}

function migrateDesign(input: ver_from.NewsletterDesign): ver_to.NewsletterDesign {
  // convert the custom background
  const custom_page_bg: undefined | ver_to.CustomPageBg =
    input.background.type === "custom"
      ? {
          _t: "custom_page_bg",
          background: toPhoto(input.background.key)!
        }
      : undefined;

  return {
    bg: input.background.key || "custom", // we may need to coerce this to the first one
    color: input.swatch,
    font: input.fontpack,
    feel: matchNewFeel(input.feel),
    ext: {
      classic_design: {
        _t: "classic_design",
        classic_feel_key: input.feel
      },
      custom_page_bg
    }
  };
}

function matchNewFeel(oldFeel: ver_from.ClassicDesignFeels): ver_to.DesignFeelKey {
  switch (oldFeel) {
    case "minimal":
      return "minimal";
    case "vintage":
      return "vintage";
    case "handwritten":
      return "handwritten";
    default:
      return "classic-legacy";
  }
}

export function migrateWidget(input: ver_from.ContentWidget): ver_to.Block {
  const m = BLOCK_MIGRATION[input._t];

  if (!m) {
    console.error("Failed to find migrator ", input._t, input);
    throw new Error(`No migrator found for ${input._t}`);
  }

  const r = m(input);
  r._lu = {
    when: +new Date(),
    type: "m", // migration
    by: _currentUserId
  };

  return r;
}

type Migrator = (input: ver_from.ContentWidget) => ver_to.Block;

const BLOCK_MIGRATION: { [P in ver_from.WidgetType]: any } = {
  "attachment.file": (input: ver_from.AttachmentFileWidget): ver_to.EmbedFileBlock => {
    const result: ver_to.EmbedFileBlock = {
      _id: id(input),
      _t: "embed.file",
      _l: "md",
      _s: input._empty ? "empty" : undefined,
      access_url: input.access_url.startsWith("/") ? `${process.env.RENDERING_TARGET_DOMAIN}${input.access_url}` : input.access_url,
      file_description: input.file_description,
      file_name: input.file_name,
      file_size_in_bytes: input.file_size_in_bytes,
      file_type: input.file_type
    };

    if (input._empty && input._defaults) {
      result._defaults = {
        access_url: input._defaults.access_url,
        file_description: input._defaults.file_description,
        file_name: input._defaults.file_name,
        file_size_in_bytes: input._defaults.file_size_in_bytes,
        file_type: input._defaults.file_type
      };
    }

    return result;
  },
  "attachment.placeholder": (input: ver_from.AttachmentFileWidget): ver_to.PlaceholderBlock => {
    return {
      _id: id(input),
      _l: "attachment",
      _t: "misc.placeholder"
    };
  },
  "button": (input: ver_from.ButtonWidget): ver_to.ButtonBlock => {
    const result: ver_to.ButtonBlock = {
      _id: id(input),
      _t: "button",
      _s: input._empty ? "empty" : undefined,
      text: input.text,
      details: input.details,
      blank: input.blank,
      cta: toCTA(input.link),
      _l: "-"
    };

    if (input._empty && input._defaults) {
      result._defaults = {
        text: input._defaults.text,
        details: input._defaults.details,
        blank: input._defaults.blank,
        cta: toCTA(input._defaults.link)
      };
    }

    return result;
  },
  "embed.google_forms": (input: ver_from.GoogleFormsWidget): ver_to.EmbedIframeBlock => {
    return {
      _id: id(input),
      _t: "embed.iframe",
      _l: "-",
      target: "google_forms",
      // TODO calculate the url
      iframe_url: `${input.base_url}${input.form_id}${input.url_suffix}`
    };
  },

  "embed.wufoo": (input: ver_from.WufooFormsWidget): ver_to.EmbedIframeBlock => {
    return {
      _id: id(input),
      _t: "embed.iframe",
      _l: "-",
      target: "wufoo"
      // TODO calculate the url
    };
  },
  "event": (input: ver_from.EventWidget): ver_to.EventBlock => {
    const result: ver_to.EventBlock = {
      _id: id(input),
      _t: "event",
      _l: "-",
      _s: input._empty ? "empty" : undefined,

      name: input.name || "",
      date: DateTimeTZUtils.fromDate(
        input.when ? toDate(input.when.start) : new Date(0),
        undefined
        // input.when && input.when.end ? toDate(input.when.end) : undefined
      ),
      location: input.where?.text,
      hideMapFromEvent: input.where?.hideMap,
      onlineEvent: input.where?.online,
      details: toRichText(input.details),
      collectRSVP: input.event_rsvp,
      notifyRSVP: input.rsvp_notify
    };

    if (input._defaults && input._defaults) {
      result._defaults = {
        name: input._defaults.name || "",
        date: DateTimeTZUtils.fromDate(
          input._defaults.when ? toDate(input._defaults.when.start) : new Date(0),
          undefined
          // input._defaults.when && input._defaults.when.end ? toDate(input._defaults.when.end) : undefined
        ),
        location: input._defaults.where?.text,
        hideMapFromEvent: input._defaults.where?.hideMap,
        onlineEvent: input._defaults.where?.online,
        details: toRichText(input._defaults?.details)
      };
    }

    return result;
  },

  "gallery": (input: ver_from.GalleryWidget): ver_to.ItemsBlock => {
    const result: ver_to.ItemsBlock = {
      _id: id(input),
      _t: "items",
      _s: input._empty ? "empty" : undefined,
      items: [
        toPhoto(input.url1, input.title1, input.description1),
        toPhoto(input.url2, input.title2, input.description2),
        toPhoto(input.url3, input.title3, input.description3)
      ].filter((i) => i !== undefined) as ver_to.Photo[],
      _l: "3-columns"
    };

    if (input._empty) {
      if (input._defaults) {
        result._defaults = {
          items: [
            toPhoto(
              input._defaults.url1 || { full: "Empty" },
              input._defaults.title1 || "Picture 1",
              input._defaults.description1 || "Click to add description"
            ),
            toPhoto(
              input._defaults.url2 || { full: "Empty" },
              input._defaults.title2 || "Picture 2",
              input._defaults.description2 || "Click to add description"
            ),
            toPhoto(
              input._defaults.url3 || { full: "Empty" },
              input._defaults.title3 || "Picture 3",
              input._defaults.description3 || "Click to add description"
            )
          ].filter((i) => i !== undefined) as ver_to.Photo[]
        };
      } else {
        result._defaults = {
          items: [
            toPhoto({ full: "Empty" }, "Add a title", "Click to add description"),
            toPhoto({ full: "Empty" }, "Add a title", "Click to add description"),
            toPhoto({ full: "Empty" }, "Add a title", "Click to add description")
          ].filter((i) => i !== undefined) as ver_to.Photo[]
        };
      }
    }

    if (result.items.length) {
      delete result._s;
    }

    return result;
  },

  "header.app": migrateHeader,

  "header.text": migrateHeader,

  "image": (input: ver_from.BigImageWidget): ver_to.ImageBlock => {
    const result = {
      _id: id(input),
      _t: "image.single",
      _l: "lg",
      _s: input._empty ? "empty" : undefined,
      photo: toPhoto(input.url)
    };

    if (input._defaults && input._defaults.url) {
      (result as ver_to.ImageBlock)._defaults = {
        photo: toPhoto(input._defaults.url)
      };
    }

    return result as ver_to.ImageBlock;
  },

  "images.placeholder": (input: ver_from.ImagesPlaceholderWidget): ver_to.PlaceholderBlock => {
    return {
      _id: id(input),
      _t: "misc.placeholder",
      _l: "image"
    };
  },

  "link": (input: ver_from.EmbedLinkWidget): ver_to.EmbedLinkBlock => {
    const result: ver_to.EmbedLinkBlock = {
      _id: id(input),
      _t: "embed.link",
      _l: "md",
      _s: input._empty ? "empty" : undefined,
      description: deNull(input.description || input.text),
      host: input.host,
      title: input.title,
      url: input.url,
      thumbnail_url: input.thumbnail_url
    };

    if (input._empty && input._defaults) {
      result._defaults = {
        description: deNull(input._defaults.description || input._defaults.text),
        host: input._defaults.host,
        title: input._defaults.title,
        url: input._defaults.url,
        thumbnail_url: input._defaults.thumbnail_url
      };
    }

    return result;
  },

  "text.bio": (input: ver_from.BioWidget): ver_to.ParagraphBlock => {
    // TODO - add all missing fields
    const result: ver_to.ParagraphBlock = {
      _id: id(input),
      _l: input.picture ? "side-l" : "-",
      _t: "text.paragraph",
      _s: input._empty ? "empty" : undefined,
      picture: toPhoto(input.picture),
      content: toRichText(input.content),
      title: input.title
    };

    const content: ver_to.RichNode[] = [];
    if (input.email) {
      content.push({ t: "strong", c: ["Email: "] }, { t: "a", c: [input.email], a: { target: "new", href: "mailto:" + input.email } });
    }
    if (input.website) {
      let site = input.website;
      if (!site.startsWith("http")) {
        site = "https://" + site;
      }
      if (content.length > 0) {
        content.push({ t: "br" });
      }
      content.push({ t: "strong", c: ["Website: "] }, { t: "a", c: [input.website], a: { target: "new", href: site } });
    }
    if (input.location && !input.location.empty && ((input.location.text || "").length || (input.location.url || "").length)) {
      if (content.length > 0) {
        content.push({ t: "br" });
      }
      content.push(
        { t: "strong", c: ["Location: "] },
        { t: "a", c: [input.location.text || input.location.url], a: { target: "new", href: input.location.url } }
      );
    }
    if (input.phone) {
      if (content.length > 0) {
        content.push({ t: "br" });
      }
      content.push({ t: "strong", c: ["Phone: "] }, { t: "a", c: [input.phone], a: { target: "new", href: `tel:${input.phone}` } });
    }
    if (input.facebook) {
      if (content.length > 0) {
        content.push({ t: "br" });
      }
      content.push(
        { t: "strong", c: ["Facebook: "] },
        { t: "a", c: [input.facebook], a: { target: "new", href: "https://" + input.facebook } }
      );
    }
    if (input.twitter) {
      if (content.length > 0) {
        content.push({ t: "br" });
      }
      content.push(
        { t: "strong", c: ["Twitter: "] },
        { t: "a", c: [input.twitter], a: { target: "new", href: "https://x.com/" + input.twitter } }
      );
    }

    if (content.length > 0) {
      result.content = [...(result.content! || []), { t: "p", c: content }];
    }

    if (input._empty && input._defaults) {
      result._defaults = {
        picture: toPhoto(input._defaults.picture),
        content: toRichText(input._defaults.content),
        title: input._defaults.title
      };
    }

    return result;
  },

  "text.paragraph": (input: ver_from.ParagraphWidget): ver_to.ParagraphBlock => {
    let _l: ver_to.ParagraphBlock["_l"] = "-";
    switch (input._v) {
      case "paragraph_image":
        _l = "side-r";
        break;
      case "paragraph_image_left":
        _l = "side-l";
        break;
    }
    const result: ver_to.ParagraphBlock = {
      _id: id(input),
      _t: "text.paragraph",
      _l,
      _s: input._empty ? "empty" : undefined,
      content: toRichText(input.content),
      picture: toPhoto(input.picture),
      title: input.title
    };

    if (input._empty && input._defaults) {
      result._defaults = {
        title: input._defaults?.title as string,
        content: toRichText(input._defaults?.content as ver_from.RichText),
        picture: toPhoto(input._defaults?.picture)
      };
    }

    return result;
  },

  "text.small_title": (input: ver_from.SmallTitleWidget): ver_to.TitleBlock => {
    const result: ver_to.TitleBlock = {
      _id: id(input),
      _t: "text.title",
      _l: "md",
      _s: input._empty ? "empty" : undefined,
      title: input.title,
      align: input._v === "small_title_left" ? "left" : "center",
      cta: toCTA(input.link)
    };

    if (input._empty && input._defaults) {
      result._defaults = {
        title: input._defaults?.title,
        cta: toCTA(input._defaults?.link)
      };
    }

    return result;
  },

  "text.title": (input: ver_from.LegacyTitleWidget): ver_to.TitleBlock => {
    const result: ver_to.TitleBlock = {
      _id: id(input),
      _t: "text.title",
      _l: "lg",
      _s: input._empty ? "empty" : undefined,
      title: input.title
    };

    if (input._empty && input._defaults) {
      result._defaults = {
        title: input._defaults.title
      };
    }

    return result;
  },

  "video.facebook": (input: ver_from.FacebookVideoWidget): ver_to.VideoBlock => {
    const result: ver_to.VideoBlock = {
      _id: id(input),
      _t: "embed.video",
      _l: "lg",
      _s: input._empty ? "empty" : undefined,
      target: "facebook",
      movie: input.video_url || "",
      url: input.video_url || "",
      thumbnail_url: deNull(input.thumbnail_url),
      title: deNull(input.title)
    };

    if (input._empty && input._defaults) {
      result._defaults = {
        target: "facebook",
        movie: input._defaults.movie,
        thumbnail_url: deNull(input._defaults.thumbnail_url),
        title: deNull(input._defaults.title)
      };
    }

    return result;
  },

  "video.viddler": (input: ver_from.ViddlerVideoWidget): ver_to.VideoBlock => {
    const result: ver_to.VideoBlock = {
      _id: id(input),
      _t: "embed.video",
      _l: "lg",
      _s: input._empty ? "empty" : undefined,
      target: "viddler",
      movie: input.movie,
      url: input.url,
      thumbnail_url: deNull(input.thumbnail_url),
      title: deNull(input.title)
    };

    if (input._empty && input._defaults) {
      result._defaults = {
        target: "viddler",
        movie: input._defaults.movie,
        thumbnail_url: deNull(input._defaults.thumbnail_url),
        title: deNull(input._defaults.title)
      };
    }

    return result;
  },

  "video.vimeo": (input: ver_from.VimeoVideoWidget): ver_to.VideoBlock => {
    const result: ver_to.VideoBlock = {
      _id: id(input),
      _t: "embed.video",
      _l: "lg",
      _s: input._empty ? "empty" : undefined,
      target: "vimeo",
      movie: input.movie,
      url: input.url,
      thumbnail_url: deNull(input.thumbnail_url),
      title: deNull(input.title)
    };

    if (input._empty && input._defaults) {
      result._defaults = {
        target: "vimeo",
        movie: input._defaults.movie,
        thumbnail_url: deNull(input._defaults.thumbnail_url),
        title: deNull(input._defaults.title)
      };
    }

    return result;
  },

  "video.youtube": (input: ver_from.YouTubeVideoWidget): ver_to.VideoBlock => {
    const result: ver_to.VideoBlock = {
      _id: id(input),
      _t: "embed.video",
      _l: "lg",
      _s: input._empty ? "empty" : undefined,
      target: "youtube",
      movie: input.movie,
      url: input.url,
      thumbnail_url: deNull(input.thumbnail_url),
      title: deNull(input.title)
    };

    if (input._empty && input._defaults) {
      result._defaults = {
        target: "youtube",
        movie: input._defaults.movie,
        thumbnail_url: deNull(input._defaults.thumbnail_url),
        title: deNull(input._defaults.title)
      };
    }

    return result;
  },

  "video.placeholder": (input: ver_from.PlaceholderVideoWidget): ver_to.PlaceholderBlock => {
    return {
      _id: id(input),
      _t: "misc.placeholder",
      _l: "video"
    };
  },

  "video.soundcloud": (input: ver_from.SoundcloudVideoWidget): ver_to.VideoBlock => {
    const result: ver_to.VideoBlock = {
      _id: id(input),
      _t: "embed.video",
      _l: "lg",
      _s: input._empty ? "empty" : undefined,
      target: "soundcloud",
      playerUrl: input.player_url,
      movie: "",
      url: input.url,
      thumbnail_url: deNull(input.thumbnail_url),
      title: deNull(input.title)
    };

    if (input._empty && input._defaults) {
      result._defaults = {
        target: "soundcloud",
        movie: "",
        playerUrl: input._defaults.player_url,
        thumbnail_url: deNull(input._defaults.thumbnail_url),
        title: deNull(input._defaults.title)
      };
    }

    return result;
  }
};

function migrateHeader(input: ver_from.HeaderWidget): ver_to.HeaderBlock {
  return {
    _id: id(input.__id),
    _l: "-",
    _t: "header",
    title: input.title,
    subtitle: input.subtitle,
    // restore the custom header
    custom: input.__ogBlock?.block?.custom
  };
}

function migrateBioToSignature(input: ver_from.BioWidget): ver_to.SignatureBlock {
  return {
    _id: id(input),
    _l: "-",
    _t: "signature",
    title: input.title,
    email: input.email,
    content: toRichText(input.content),
    facebook: input.facebook,
    picture: toPhoto(input.picture),
    website: input.website,
    twitter: input.twitter,
    phone: input.phone
  };
}

function toCTA(url?: string): ver_to.CallToAction | undefined {
  if (!url) {
    return undefined;
  }
  return {
    id: id(),
    url
  };
}

function toPhoto(input?: ver_from.Photo | string | null, title?: string, description?: ver_from.RichText): ver_to.Photo | undefined {
  if (!input) {
    return undefined;
  }

  if (typeof input === "string") {
    return {
      id: id(),
      legacy_full_url: input
    };
  }

  return {
    id: id(),
    description: toRichText(description),
    alt_text: input.altText,
    legacy_full_url: input.full,
    legacy_thumb_url: input.thumb,
    cta: toCTA(input.linkTarget),
    title,
    transformations: input.cropInfo ? { crop: { ...input.cropInfo } } : undefined
  };
}

function deNull<T>(input: T | null): undefined | T {
  return input ? input : undefined;
}

function toRichText(input?: ver_from.RichText | null): ver_to.RichText | undefined {
  if (!input) {
    return undefined;
  }

  // Here we just return the input (as we're going to keep the format the same)
  return input;
}

export function id(input?: string | null | ver_from.Widget): string {
  if (input && typeof input === "object") {
    return input["__id"] || id();
  }

  if (input && input.startsWith("w-")) {
    return input;
  }

  return `w-${Math.random().toString(36).substr(2, 9)}`;
}

function toDate(date?: v0Date): Date {
  return date ? new Date(date.year || 0, fixMonth(date.month), date.day || 0, date.hour || 0, date.minute || 0) : new Date(0);
}

function fixMonth(month: number | null) {
  if (month && month > 1) {
    return month - 1;
  }
  return 0;
}

import type { Writable } from "svelte/store";
import { writable } from "svelte/store";

const _stores: Record<string, Writable<any>> = {};

export function userStorage<T>(key: string, initial: T) {
  return storage<T & { id: string }>(key, { id: "", ...initial });
}

// Create a local storage backed writable store
export function storage<T>(key: string, initial: T): Writable<T> {
  if (import.meta.env.SSR || !localStorage) {
    return writable(initial);
  }

  const v = JSON.parse(localStorage.getItem(key) ?? "null") ?? initial;

  if (_stores[key]) {
    return _stores[key] as Writable<T>;
  }

  // use the cache
  const store = (_stores[key] = writable<T>(v));

  store.subscribe((newVal) => {
    localStorage.setItem(key, JSON.stringify(newVal));
  });

  return store;
}
